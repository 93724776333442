import type { Firestore, WhereFilterOp } from "firebase/firestore"
import { updateDoc } from "firebase/firestore"
import { collection, doc, DocumentReference, getDoc, getFirestore, onSnapshot, query, where } from "firebase/firestore"
import { uuid } from "@/utils/uuid"

export function initialize() {
  window.unityBridge.firestore = new FirestoreBridge()
}

type UnSubscribeArray = {
  [listenId: string]: () => void
}

type QueryParameter = [string, WhereFilterOp, any]

class FirestoreBridge {
  private db: Firestore
  private unsubs: UnSubscribeArray = {}

  constructor() {
    this.db = getFirestore()
  }

  async getDocument(collectionId: string, documentId: string, callbackObject: string, callbackMethod: string) {
    try {
      const docRef = doc(this.db, collectionId, documentId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        window.unityInstance.SendMessage(
          callbackObject,
          callbackMethod,
          JSON.stringify(FirestoreBridge.customizeToJSON(docSnap.data()))
        )
        return
      }
    } catch (error: any) {
      console.warn(error)
    }
    window.unityInstance.SendMessage(callbackObject, callbackMethod, "null")
  }

  listenDocument(collectionId: string, documentId: string, callbackObject: string, callbackMethod: string): string {
    const unsub = onSnapshot(doc(this.db, collectionId, documentId), (snapshot) => {
      window.unityInstance.SendMessage(
        callbackObject,
        callbackMethod,
        JSON.stringify(FirestoreBridge.customizeToJSON(snapshot.data())) ?? "null"
      )
    })
    const listenId = uuid()
    this.unsubs[listenId] = unsub
    return listenId
  }

  listenDocuments(
    collectionId: string,
    queries: QueryParameter[],
    callbackObject: string,
    callbackMethod: string
  ): string {
    const whereClause = queries.map((q) => where(...q))
    const q = query(collection(this.db, collectionId), ...whereClause)
    const unsub = onSnapshot(q, (querySnapshot) => {
      const items = []
      querySnapshot.forEach((d) => {
        items.push(FirestoreBridge.customizeToJSON(d.data()))
      })
      window.unityInstance.SendMessage(callbackObject, callbackMethod, JSON.stringify(items))
    })
    const listenId = uuid()
    this.unsubs[listenId] = unsub
    return listenId
  }

  stopListening(listenId: string) {
    if (listenId in this.unsubs) {
      this.unsubs[listenId]()
      delete this.unsubs[listenId]
    }
  }

  updateDocument(
    collectionId: string,
    documentId: string,
    value: any,
    callbackObject: string,
    callbackMethod: string,
    fallbackMethod: string
  ) {
    const docRef = doc(this.db, collectionId, documentId)
    updateDoc(docRef, value)
      .then(() => {
        window.unityInstance.SendMessage(callbackObject, callbackMethod, "null")
      })
      .catch((error: any) => {
        if (fallbackMethod) {
          window.unityInstance.SendMessage(
            callbackObject,
            fallbackMethod,
            JSON.stringify(error, Object.getOwnPropertyNames(error))
          )
        }
      })
  }

  /**
   * Reference 型の値をシリアライズする処理を上書きします。
   */
  static customizeToJSON(value: any): any {
    if (Array.isArray(value)) {
      // is Array
      value.forEach((item) => FirestoreBridge.customizeToJSON(item))
    } else if (value !== null && typeof value === "object") {
      if (value instanceof DocumentReference) {
        // is Reference
        ;(<any>value).toJSON = () => {
          return {
            type: "reference",
            path: value.path,
          }
        }
      } else {
        // is Map
        Object.keys(value).forEach((k) => FirestoreBridge.customizeToJSON(value[k]))
      }
    }
    return value
  }
}
