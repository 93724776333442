import { httpsCallable } from "firebase/functions"
import { getFunctionsInTokyoRegion } from "@/utils/firebase"

export function initialize() {
  window.unityBridge.functions = new FunctionsBridge()
}

class FunctionsBridge {
  async call(methodName: string, params: any, callbackObject: string, callbackMethod: string) {
    const functions = getFunctionsInTokyoRegion()
    const method = httpsCallable(functions, methodName)
    try {
      const result = await method(params)
      window.unityInstance.SendMessage(callbackObject, callbackMethod, JSON.stringify(result.data))
    } catch (error) {
      console.error(error)
    }
  }
}
