import { getAuth } from "firebase/auth"

export function initialize() {
  window.unityBridge.auth = new AuthenticationBridge()
}

class AuthenticationBridge {
  constructor() {}

  getUserId(): string {
    return getAuth().currentUser.uid
  }
}
