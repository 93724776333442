import { initialize as initializeChat } from "./chat"
import { initialize as initializeAnalytics } from "./firebase/analytics"
import { initialize as initializeAuthentication } from "./firebase/auth"
import { initialize as initializeFirestore } from "./firebase/firestore"
import { initialize as initializeFunctions } from "./firebase/functions"
import { initialize as initializeStorage } from "./firebase/storage"

export async function init() {
  if (window.unityBridge) {
    return
  }

  // Unity との相互作用オブジェクト
  window.unityBridge = {}

  const skyway = await import("./skyway")
  skyway.initialize()

  initializeChat()
  initializeAnalytics()
  initializeAuthentication()
  initializeFirestore()
  initializeFunctions()
  initializeStorage()
}
