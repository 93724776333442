import type { Firestore } from "firebase/firestore"
import {
  addDoc,
  collection,
  serverTimestamp,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore"

import emojiRegex from "emoji-regex"

export function initialize() {
  window.unityBridge.ChatClient = ChatClient
}

const MAX_TEXT_LENGTH = 50

class ChatClient {
  private db: Firestore
  private unsubscribe: () => void
  private textChatCount = []

  constructor(private room: string, private nickname: string, private userId: string) {
    this.db = getFirestore()

    const expirationDate = Timestamp.now()

    const removeEmoji = (input: string) => input.replace(emojiRegex(), "")

    // Create the query to load and listen for new ones.
    const chatsRef = collection(this.db, "textChat")
    const q = query(
      chatsRef,
      where("timestamp", ">=", expirationDate),
      where("flag", "==", true),
      orderBy("timestamp", "desc"),
      limit(100)
    )

    // Start listening to the query.
    this.unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          const obj = {
            ChatId: change.doc.id,
          }
          window.unityInstance.SendMessage("ChatController", "OnReceiveDeleteChatMessage", JSON.stringify(obj))
        } else {
          const message = change.doc.data() as ChatMessage

          // TODO:絵文字がUI上で表示できるようなったらここの処理は削除
          let textRemoveEmoji = removeEmoji(message.text)
          if (textRemoveEmoji.length > 0) {
            const obj = {
              ChatId: change.doc.id,
              Nickname: message.name,
              Message: textRemoveEmoji,
              UserId: message.userId,
            }
            window.unityInstance.SendMessage("ChatController", "OnReceiveChatMessage", JSON.stringify(obj))
          }
        }
      })
    })
  }

  dispose() {
    this.unsubscribe()
  }

  send(msg: string) {
    const now = new Date()
    const unixtime = now.getTime()
    //連投チェック
    //30秒より前のチャットカウントは削除
    this.textChatCount = this.textChatCount.filter((item) => item >= unixtime - 30000)
    //チャット10件を超えていたらエラー
    if (this.textChatCount.length >= 11) {
      console.error("Error: Please Wait!")
      return
    }

    //バリデート
    msg = msg.trim()

    //サロゲートペア文字列踏まえ文字数チェック
    var text = msg.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || []
    if (text.length > MAX_TEXT_LENGTH) {
      console.error("Error: You have exceeded the maximum number of characters.")
      return
    }

    const docData = {
      room: this.room,
      name: this.nickname,
      text: msg,
      timestamp: serverTimestamp(),
      flag: false,
      userId: this.userId,
    }
    addDoc(collection(this.db, "textChat"), docData).catch((error) => {
      console.error("Error writing new message to Firebase Database", error)
    })

    //チャットカウント追加
    this.textChatCount.push(now.getTime())
  }
}
