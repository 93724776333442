<script lang="ts">
  import { onMount } from "svelte"
  import { getAuth, signOut } from "firebase/auth"

  import { getAngle, notify as orientationNotify } from "@/unity-bridge/orientation"

  import { clearCache, getBuildNo } from "@/utils/cache"
  import Modal from "./modal.svelte"

  let loading: boolean = true
  let showModal = false
  const displayLogoutButton = import.meta.env.VITE_LIMIT_ACCESS

  onMount(async () => {
    const buildNo = await getBuildNo()
    await clearCache(buildNo)

    startUnityApplication(
      buildNo,
      (unityInstance: UnityInstance) => {
        window.unityInstance = unityInstance

        // 初期状態を通知
        window.unityInstance.SendMessage("Application", "SetOrientation", getAngle())

        loading = false
      },
      (message?: any) => {
        if (message) {
          console.error(message)
        }
        showModal = true
      }
    )

    onResize()

    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  })

  function onResize() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  function onClickLogout() {
    const auth = getAuth()
    signOut(auth)
  }
</script>

<div id="unity-container" use:orientationNotify>
  <div id="unity-canvas-container">
    <canvas id="unity-canvas" style="width: 100%; height: 100%" />
  </div>
  <div id="unity-loading" class:loading>
    <h2>バーチャル東京アニメセンター</h2>
    <!-- <div id="unity-loading-logo" /> -->
    <div id="unity-loading-bar">
      <div id="unity-progress-bar-empty">
        <div id="unity-progress-bar-full" />
      </div>
    </div>
    <div class="notice-text">
      <span class="warning-audio">
        本サービスでは一部音声が流れます。周辺の環境に合わせて音量を調整してマナーにご配慮ください。
      </span>
      <span class="warning-text icon">
        iOS15.2以降をご利用の方は、動画視聴にあたり設定が必要となります。歯車アイコンをご確認ください。
      </span>
      <span class="warning-text">
        推奨環境：Android｜OS：Android9以降　iOS｜機種：iPhoneXシリーズ以降 OS：iOS15以降
      </span>
      <span>本サービスは動画再生を含むため、Wi-Fiでのご利用を推奨しております。</span>
      <span>ご利用のネット回線速度によっては、読み込みに数分ほどかかる場合がございます。</span>
    </div>
  </div>

  {#if displayLogoutButton}
    <div class="firebase-logout-button" on:click={onClickLogout}>Logout</div>
  {/if}

  <Modal {showModal} showClose={false} on:ok={() => location.reload()}>
    <p>
      アプリケーション読み込み中にエラーが発生しました。<br />
      ページを再読み込みします。
    </p>
  </Modal>

  <style>
    body {
      background-color: #222;
    }
  </style>
</div>

<style>
  #unity-container {
    min-height: 100vh; /* Fallback */
    min-height: calc(var(--vh, 1vh) * 100);
  }
  #unity-canvas-container {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  #unity-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    color: white;
    box-sizing: border-box;
  }
  h2 {
    position: absolute;
    width: 90%;
    top: calc(43% - 4.5em);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  #unity-loading.loading {
    display: flex;
  }
  #unity-loading-logo {
    position: absolute;
    width: 90%;
    height: 45px;
    top: calc(40% - 75px);
    left: 50%;
    transform: translate(-50%);
    background: url(/logo.png) no-repeat center;
    background-size: contain;
  }
  #unity-loading-bar {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
  span {
    font-family: monospace;
    font-size: 0.95rem;
    line-height: 1.625;
  }
  .notice-text {
    position: absolute;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .notice-text > span:last-child {
    margin-bottom: auto;
  }
  .warning-audio {
    font-size: 1.1rem;
    margin-bottom: auto;
  }
  .warning-text {
    display: flex;
    align-items: center;
    color: #ff5050;
  }
  .warning-text.icon::before {
    content: "";
    display: block;
    width: 2em;
    height: 2em;
    padding-right: 0.5em;
    background: url("/TemplateData/warning-icon.png") no-repeat center;
    background-size: contain;
  }
  #unity-progress-bar-empty {
    width: 141px;
    height: 18px;
    margin-top: 10px;
    background: url("/TemplateData/progress-bar-empty-dark.png") no-repeat center;
  }
  #unity-progress-bar-full {
    width: 0%;
    height: 18px;
    margin-top: 10px;
    background: url("/TemplateData/progress-bar-full-dark.png") no-repeat center;
  }
  .firebase-logout-button {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    padding: 0.5em 1em;
    color: white;
    background: deepskyblue;
    cursor: pointer;
  }
</style>
