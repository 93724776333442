import type { Analytics } from "firebase/analytics"
import { getAnalytics, logEvent } from "firebase/analytics"

export function initialize() {
  window.unityBridge.analytics = new AnalyticsBridge()
}

class AnalyticsBridge {
  private analytics: Analytics

  constructor() {
    this.analytics = getAnalytics()
  }

  logEvent(eventName: string, params: any) {
    logEvent(this.analytics, eventName, params)
  }
}
