<script lang="ts">
  import type { SvelteComponent } from "svelte"
  import { writable } from "svelte/store"

  import type { User } from "firebase/auth"
  import {
    browserSessionPersistence,
    getAuth,
    onAuthStateChanged,
    setPersistence,
    signInAnonymously,
    signOut,
  } from "firebase/auth"

  import { init as initFirebase, setAnalyticsUser, startWatchSession } from "./utils/firebase"
  import { signInWithCode, getMaintStatus, MAINT_STATUS } from "./utils/firebase"
  import { init as initUnityBridge } from "./unity-bridge"
  import UnityApplication from "./components/unity-application.svelte"
  // 検証環境でしか使わないので、
  // dynamic import して本番環境などでは Tree shaking されるように
  const Login = writable<SvelteComponent>(null)

  let promise = init()
  let isLoggedin: boolean | null = null
  let maintStatus: number = MAINT_STATUS.BEFORE_OPEN
  let isTest: number = 0

  $: isDoubleLogin = false

  async function init() {
    // Firebase を初期化
    await initFirebase()

    // ログイン管理
    const auth = getAuth()
    auth.languageCode = "ja"
    setPersistence(auth, browserSessionPersistence)

    maintStatus = await getMaintStatus()

    if (!import.meta.env.VITE_LIMIT_ACCESS) {
      const searchParams = new URLSearchParams(location.search)

      if (searchParams.has("code")) {
        // 認証コードでログイン
        isTest = await signInWithCode(auth, searchParams.get("code"))
      } else {
        // 匿名認証でログイン
        await signInAnonymously(auth)
        // throw new Error("認証付きURLでアクセスしてください")
      }
    } else {
      // @ts-ignore
      $Login = (await import("./components/login.svelte")).default
    }

    onAuthStateChanged(auth, async (user: User) => {
      if (user) {
        // staging ビルドの場合アクセス権限をチェック
        if (import.meta.env.VITE_LIMIT_ACCESS) {
          // @ts-ignore
          const mod = await import("./utils/staging.ts")
          const result = await mod.accessAuthorizations()
          if (!result) {
            signOut(auth)
            return
          }
        }

        // 認証
        startWatchSession(user, () => {
          console.error("isDoubleLogin")
          isDoubleLogin = true
        })

        // Analytics にユーザー情報を紐付ける
        setAnalyticsUser(user)
        // Unity との相互作用を初期化
        await initUnityBridge()

        // User is signed in.
        isLoggedin = true
      } else {
        // No user is signed in.
        isLoggedin = false
      }
    })
  }
</script>

{#await promise then}
  {#if maintStatus == MAINT_STATUS.BEFORE_OPEN && isTest != 1}
    <p>Comming Soon</p>
  {:else if maintStatus == MAINT_STATUS.CLOSE && isTest != 1}
    <p>本日のイベントは終了しました。</p>
  {:else if isLoggedin == null}
    <!-- waiting... -->
  {:else if isDoubleLogin}
    <p style="color: red">多重ログインが発生しました。</p>
  {:else if isLoggedin}
    <UnityApplication />
  {:else}
    <svelte:component this={$Login} />
  {/if}
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}

<style>
  :global(body) {
    padding: 0;
    margin: 0;
    transition: background-color 0.5s ease-out;
    -webkit-text-size-adjust: 100%;
  }

  @media (max-width: 767.98px) {
    :global(html) {
      font-size: 12px;
    }
  }

  p {
    padding: 1em;
  }
</style>
