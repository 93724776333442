<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { scale } from "svelte/transition"

  const dispatch = createEventDispatcher()

  export let labelOK = "OK"
  export let showClose = true
  export let showModal = false

  function onClose() {
    showModal = false
    dispatch("close")
  }

  function onOK() {
    showModal = false
    dispatch("ok")
  }
</script>

{#if showModal}
  <div class="modal-outer">
    <div class="modal" in:scale={{ duration: 250, opacity: 0.5, start: 0.8 }}>
      {#if showClose}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="btn-close"
          viewBox="0 0 16 16"
          on:click|stopPropagation={onClose}
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      {/if}
      <div>
        <slot />
      </div>
      <div class="footer">
        <button class="btn" on:click|stopPropagation={onOK}>{labelOK}</button>
      </div>
    </div>
  </div>
{/if}

<style>
  .modal-outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0006;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 30px 15px 15px;
    min-width: 300px;
    min-height: 180px;
    background: white;
    border-radius: 0.5em;
  }
  .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    fill: gray;
    cursor: pointer;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }
  .btn {
    font-size: 1rem;
    font-weight: bold;
    color: white;
    padding: 0.5em 1em;
    background-color: #6c757d;
    border: none;
    border-radius: 0.25em;
    outline: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .btn:focus {
    background-color: #5c636a;
    box-shadow: 0 0 0 0.25rem rgb(130 138 145 / 50%);
  }
  .btn:active {
    background-color: #565e64;
  }
</style>
